import {
    GET_POSTS,
    GET_FILTERED_POSTS,
    GET_POST,
    GET_BLOG_CATEGORIES,
    GET_BLOG_CATEGORY,
    GET_TAGS,
    GET_FORMATTED_TAGS,
    GET_TAG,
    SET_LOADING,
} from '../types';

const fn = () => (state, action) => {

    switch(action.type){

        case GET_POSTS: 
            return {
                ...state,
                posts: action.payload,
                loading: false
            }
        case GET_FILTERED_POSTS: 
            return {
                ...state,
                filteredPosts: action.payload,
                loading: false
            }
        case GET_POST: 
            return {
                ...state,
                post: action.payload,
                loading: false
            }

        case GET_BLOG_CATEGORIES: 
            return {
                ...state,
                categories: action.payload,
                loading: false
            }
        case GET_BLOG_CATEGORY: 
            return {
                ...state,
                category: action.payload,
                loading: false
            }

        case GET_TAGS: 
            return {
                ...state,
                tags: action.payload,
                loading: false
            }
        case GET_FORMATTED_TAGS: 
            return {
                ...state,
                formattedTags: action.payload,
                loading: false
            }
        case GET_TAG: 
            return {
                ...state,
                tag: action.payload,
                loading: false
            }

        case SET_LOADING: 
            return {
                ...state,
                loading: true
            }
            
        default: 
            return state;
    }

}
export default fn;