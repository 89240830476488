import React, { useReducer } from 'react';
import { useNavigate } from 'react-router-dom'
import Axios from 'axios';
import storage from '../../components/helpers/storage'
import loader from '../../components/helpers/loader'

import BlogContext from './blogContext';
import BlogReducer from './blogReducer';


import {
    GET_POSTS,
    GET_POST,
    GET_BLOG_CATEGORIES,
    GET_BLOG_CATEGORY,
    GET_TAGS,
    GET_FORMATTED_TAGS,
    GET_TAG,
    SET_LOADING,
    SET_PAGINATION,
    SET_TOTAL,
    GET_FILTERED_POSTS
} from '../types'

const BlogState = (props) => {

    const history = useNavigate();
    Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

    const initialState = {
        posts: [],
        filteredPosts: [],
        post: {},
        tags: [],
        formattedTags: [],
        tag: {},
        categories: [],
        category: {},
        comments: [],
        comment: {},
        total: 0,
        pagination: {},
        loading: false,
    }

    const [state, dispatch] = useReducer(BlogReducer, initialState);

    const logout = async (e) => {

        if(e) e.preventDefault();

        storage.clearAuth();
        history.push('/');
        await Axios.post(`${process.env.REACT_APP_AUTH_URL}/auth/logout`,{}, storage.getConfig());
    }

    const getPosts = async (limit, page) => {

        const q = `platform=${'myrioi'}&limit=${limit && limit !== '' ? limit : '30'}&page=${page ? page : '1'}&sort=desc`

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/posts/get-posts?${q}`, storage.getConfig())
                .then((resp) => {

                    dispatch({
                        type: GET_POSTS,
                        payload: resp.data.data
                    });

                    dispatch({
                        type: SET_PAGINATION,
                        payload: resp.data.pagination
                    })
    
                    dispatch({
                        type: SET_TOTAL,
                        payload: resp.data.total
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get posts ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get posts ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get posts ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get posts ${err}`)
    
                }
                
            }

        

    }

    const getPost = async (slug) => {

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/posts/get-by-slug/${slug}`, storage.getConfig())
                .then((resp) => {

                    dispatch({
                        type: GET_POST,
                        payload: resp.data.data
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get post ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get post ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get post ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get post ${err}`)
    
                }
                
            }

        

    }

    const getCategories = async () => {

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/categories/all?platform=myrioi`, storage.getConfig())
                .then((resp) => {

                    dispatch({
                        type: GET_BLOG_CATEGORIES,
                        payload: resp.data.data
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get blog categories ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get blog categories ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get blog categories ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get blog categories ${err}`)
    
                }
                
            }

        

    }

    const getCategory = async (id) => {

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/categories/${id}`, storage.getConfig())
                .then((resp) => {

                    dispatch({
                        type: GET_BLOG_CATEGORY,
                        payload: resp.data.data
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get category ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get category ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get category ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get category ${err}`)
    
                }
                
            }

        

    }

    const getTags = async () => {

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/tags/all?platform=myrioi`, storage.getConfig())
                .then((resp) => {

                    if(resp.data.data.length > 0){

                        const newData = resp.data.data.map((item) => { 

                            let tag = {
                                value: item.name,
                                label: item.name
                            }
                            return tag;
            
                        })

                        dispatch({
                            type: GET_FORMATTED_TAGS,
                            payload: newData
                        });

                    }

                    dispatch({
                        type: GET_TAGS,
                        payload: resp.data.data
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get tags ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get tags ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get tags ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get tags ${err}`)
    
                }
                
            }

        

    }

    const getTag = async (id) => {

        setLoading()
            try {

                await Axios.get(`${process.env.REACT_APP_BLOG_URL}/tags/${id}`, storage.getConfig())
                .then((resp) => {

                    dispatch({
                        type: GET_TAG,
                        payload: resp.data.data
                    });

                }).catch((err) => {

                    if(err && err.response && err.response.data && err.response.data.status === 401){

                        logout();
        
                    }else if(err && err.response && err.response.data){
        
                        console.log(`Error! Could not get tag ${err.response.data}`)
        
                    }else if(err && err.toString() === 'Error: Network Error'){
        
                        loader.popNetwork();
        
                    }else if(err){
        
                        console.log(`Error! Could not get tag ${err}`)
        
                    }
                    
                })
                
            } catch (err) {
                
                if(err && err.response && err.response.data && err.response.data.status === 401){

                    logout();
    
                }else if(err && err.response && err.response.data){
    
                    console.log(`Error! Could not get tag ${err.response.data}`)
    
                }else if(err && err.toString() === 'Error: Network Error'){
    
                    loader.popNetwork();
    
                }else if(err){
    
                    console.log(`Error! Could not get tag ${err}`)
    
                }
                
            }

        

    }

    const setLoading = () => {
        dispatch({
            type: SET_LOADING
        })
    }

    const setPosts = (data) => {
        dispatch({
            type: GET_POSTS,
            payload: data
        })
    }

    const setFilteredPosts = (data) => {
        dispatch({
            type: GET_FILTERED_POSTS,
            payload: data
        })
    }

    return <BlogContext.Provider
        value={{
            posts: state.posts,
            filteredPosts: state.filteredPosts,
            post: state.post,
            tags: state.tags,
            formattedTags: state.formattedTags,
            tag: state.tag,
            categories: state.categories,
            category: state.category,
            total: state.total,
            pagination: state.pagination,
            loading: state.loading,
            getCategories,
            getCategory,
            getPost,
            getPosts,
            getTags,
            getTag,
            setPosts,
            setFilteredPosts
        }}
    >
        {props.children}

    </BlogContext.Provider>

    

}

export default BlogState;