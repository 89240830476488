export const GET_LOGGEDIN_USER = 'GET_LOGGEDIN_USER';
export const GET_USER = 'GET_USER';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_BANKS = 'GET_BANKS';
export const GET_LOCATIONS = 'GET_LOCATIONS';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';

export const GET_BLOG_CATEGORIES = 'GET_BLOG_CATEGORIES';
export const GET_BLOG_CATEGORY = 'GET_BLOG_CATEGORY';
export const GET_TAGS = 'GET_TAGS';
export const GET_FORMATTED_TAGS = 'GET_FORMATTED_TAGS';
export const GET_TAG = 'GET_TAG';
export const GET_COMMNENTS = 'GET_COMMNENTS';
export const GET_COMMNENT = 'GET_COMMNENT';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const GET_FILTERED_POSTS = 'GET_FILTERED_POSTS';

// generics
export const SET_PAGINATION = 'SET_PAGINATION';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_LOADING = 'SET_LOADING';