import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import loader from './components/helpers/loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorUI from './components/layouts/globals/ErrorUI';

// import DashboardLayout from './components/layouts/globals/Dashboard';

import ResourceState from './context/resource/resourceState';
import UserState from './context/user/userState';
import BlogState from './context/blog/blogState';

//
const Home = React.lazy(() => import('./components/pages/Home'));
const About = React.lazy(() => import('./components/pages/About'));
const Works = React.lazy(() => import('./components/pages/Works'));
const Products = React.lazy(() => import('./components/pages/Products'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Blog = React.lazy(() => import('./components/pages/blog/Blog'));
const Article = React.lazy(() => import('./components/pages/blog/Article'));


const App = () => {

  const errorHandler = (err, info) => {
    console.log(err, 'logged error');
    console.log(info, 'logged error info');
  }

  

  return(

    <Router>

        <UserState>

          <BlogState>

              <ResourceState>

                  <Suspense fallback={loader.MainLoader()}>

                      <ErrorBoundary FallbackComponent={ErrorUI} onReset={() => { window.location.reload() }} onError={errorHandler}>

                          <Routes>

                              <Route path='/' element={<Home />} />
                              <Route path='/about' element={<About />} />
                              <Route path='/works' element={<Works />} />
                              <Route path='/products' element={<Products />} />
                              <Route path='/contact' element={<Contact />} />
                              <Route path='/blog' element={<Blog />} />
                              <Route path='/blog/:slug' element={<Article />} />

                          </Routes>

                      </ErrorBoundary>

                  </Suspense>

              </ResourceState>

            </BlogState>

        </UserState>

    </Router>

  )

}

export default App;